import { RequireADPType } from "@amedia/adplogger-logger/declarations/logger";
import { meta, Meta } from "@amedia/adplogger-logger/declarations/store/state";
import store from "@amedia/adplogger-logger/src/shared/state";
import { SDKState } from "src/store";

type Payloads<T extends Meta> = RequireADPType<"payload", T>;
/**
 * Subscribes to ADPEvents and sends data {@link declarations.RequireADPType | Payload | null} and array of data {@link declarations.RequireADPType | Payload[] }
 *
 * When callback fires first time, data is always null.
 *
 * arr is sending all event data accumulated.
 * @example
 * Create a basic subscription to clickEvents:
 *
 * ```ts
 * import {subscribeToADPEvent} from '@amedia/adplogger-sdk'
 *
 * subscribeToADPEvent('InscreenEvent', event => {
 *     console.log(event)
 * })
 * ```
 *
 * {@link https://secure.amedia.cloud/api/amedia-sandbox/v1/adplogger/sdk/observe-event/JxHRToIKeM4wWBpmZ7kq | Playground}
 *
 * @example
 * Unsubscribe from events
 *
 * ```ts
 * import {subscribeToADPEvent} from '@amedia/adplogger-sdk'
 *
 * const subscriber = await subscribeToADPEvent('InscreenEvent', event => {
 *  console.log(event)
 * })
 *
 * subscriber.unsubscribe()
 * ```
 *
 *
 * @param element Takes an event of typeof {@link declarations.ADPMeta}
 * @param callback Takes a callback fuction of 2 named params, data as {@link declarations.ADPMeta} and arr as array of {@link declarations.ADPMeta}.
 */

export function subscribeToADPMeta<
  Element extends Meta,
  Payload extends Payloads<Element>
>(
  element: Element,
  callback: ({
    data,
    arr,
  }: {
    data: Payload | null;
    arr: Array<Payload>;
  }) => void
) {
  if (!isMeta(element)) return;
  let subscriber: {
    unsubscribe: () => void;
  };
  const sdkStore = SDKState.store;
  if (sdkStore) {
    const obj = {
      data: null,
      arr: sdkStore.state[`${element}s`],
    } as unknown as { data: Payload; arr: Array<Payload> };
    callback(obj);
    subscriber = sdkStore.events.subscribe(element, (data) => {
      const obj = {
        data,
        arr: sdkStore.state[`${element}s`],
      } as unknown as { data: Payload; arr: Array<Payload> };
      callback(obj);
    });
    return subscriber;
  } else {
    store.then((store) => {
      store.events.subscribe("Page", (page) => {
        if (page) {
          const obj = {
            data: null,
            arr: store.state[`${element}s`],
          } as unknown as { data: Payload; arr: Array<Payload> };
          callback(obj);
          subscriber = store.events.subscribe(element, (data) => {
            const obj = {
              data,
              arr: store.state[`${element}s`],
            } as unknown as { data: Payload; arr: Array<Payload> };
            callback(obj);
          });
        }
      });
    });
  }
  return subscriber;
}

const isMeta = (event: string): event is Meta => {
  return meta.indexOf(event as Meta) > -1;
};