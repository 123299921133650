import {
  AddMetaElementOptions,
  ADPMeta,
} from "@amedia/adplogger-logger/declarations";
import {
  ADPUnionMemberMeta,
  PickFromADP,
  RequireADPType,
} from "@amedia/adplogger-logger/declarations/logger";
import { MetaNode } from "@amedia/adplogger-logger/src/shared/logger";
import { SDKState } from "src/store";
import { setElement } from "./set-element";

type MetaElementWithoutId<T extends ADPUnionMemberMeta> = Omit<
  RequireADPType<"payload", T>,
  "id"
>;

const allowedMeta = [
  "WPAdBlock",
  "Article",
  "CollectionPage",
  "CustomElement",
  "MetaEmbed",
  "Teaser",
  "Video",
  "CallToAction",
] as const;
type AllowedMeta = (typeof allowedMeta)[number];

/**
 * Adds MetaElement to adplogger and sends it after an event has been triggered.
 * @param element Takes an HTMLElement. This is the element you want to log.
 * @param type Takes a type of {@link declarations.ADPMeta}
 * @param data Takes a payload of {@link declarations.ADPMeta}
 * @param options Takes options of {@link declarations.AddMetaElementOptions}
 * @param callback Callback that emits an element of type {@link exported.MetaNode}
 *
 * @example
 *
 * Logging of an element
 * ```html
 * <div id="content">
 *      This is content
 * </div>
 * ```
 *
 * ```ts
 * import { addMetaElement } from '@amedia/adplogger-sdk'
 *
 * const element = document.getElementById('content')
 *
 * addMetaElement(element, 'Article', {
 *     adpType: 'Article'
 * }, null, metaElement => {
 *     console.log(metaElement)
 * })
 * ```
 *
 * {@link https://secure.amedia.cloud/api/amedia-sandbox/v1/adplogger/sdk/add-meta/pYD4kAlEU6OuxmFA5f6C | Playground}
 *
 * @example
 *
 * Logging of an element in correct order. Use waitFor in order to wait on parent elements
 *
 * ```ts
 * import { addMetaElement } from '@amedia/adplogger-sdk'
 *
 * const parentElement = document.createElement('div')
 * parentElement.id = 'parent'
 * parentElement.innerHTML = 'I am a parent element'
 *
 * const childElement = document.createElement('div')
 * childElement.id = 'child'
 * childElement.innerHTML = 'I am a child element'
 *
 * addMetaElement(childElement, 'Teaser', {
 *     url: 'http://www.example.com',
 *     title: 'I am a child'
 * }, {
 *     waitFor: [parentElement]
 * }, metaElement => {
 *     console.log('Child element', metaElement)
 * })
 *
 * setTimeout(() => {
 *     addMetaElement(parentElement, 'Article', {
 *         title: 'I am a parent'
 *     }, null, metaElement => {
 *         console.log('Parent element', metaElement)
 *     })
 * }, 3000)
 *
 * parentElement.appendChild(childElement)
 * document.body.append(parentElement)
 * ```
 *
 * {@link https://secure.amedia.cloud/api/amedia-sandbox/v1/adplogger/sdk/add-meta-parent-child/QKjf6NDSMlV8ulpRz6S1 | Playground}
 */
export function addMetaElement<
  MetaElementType extends PickFromADP<ADPMeta, AllowedMeta>,
  MetaElementData extends MetaElementWithoutId<MetaElementType>
>(
  element: HTMLElement = null,
  type: MetaElementType,
  data: MetaElementData,
  options?: AddMetaElementOptions | null,
  callback?: (metaElement: MetaNode) => void
) {
  if (!isAllowed(type)) {
    console.error(
      `${JSON.stringify(
        type
      )} is not allowed. Allowed types are: ${JSON.stringify(allowedMeta)}`
    );
    return;
  }
  const { isReady } = SDKState;
  if (isReady) {
    setElement(element, type, data, options, callback);
  } else {
    SDKState.unsentMetaElements.push(
      setElement.bind(this, element, type, data, options, callback)
    );
  }
}

const isAllowed = (type: AllowedMeta): type is AllowedMeta =>
  allowedMeta.includes(type);
