import { LogElementOptions } from "@amedia/adplogger-logger/declarations/sdk/types";
import { lookup, MetaNode } from "@amedia/adplogger-logger/src/shared/logger";
import { PubSub } from "@amedia/adplogger-logger/src/shared/state/lib/pubsub";
import { getElement } from "@amedia/adplogger-logger/src/shared/utils";

const notLogged = [];
const pubSub = new PubSub();
const observer = new MutationObserver(() => {
  notLogged.forEach(({ element, type, data, options, clb }, index) => {
    const hasLoggedElement = logElement(element, type, data, options, clb);
    if (hasLoggedElement) {
      notLogged.splice(index, 1);
    }
  });
});

export function setElement(
  element: HTMLElement,
  type: any,
  data: any,
  options: LogElementOptions,
  clb: (metaElement) => void
) {
  //Mutation observer to obseve changes in the subtree of the element
  //If the child elemnt wasn't attached to the DOM
  //It will be attached to the DOM when the parent is attached to the dom
  observer.observe(element, { subtree: true, childList: true });
  //Check if the element is connected to the dom
  if (element.isConnected) {
    logElement(element, type, data, options, clb);
  } else {
    //If not, we add it to the list of elements to log later
    notLogged.push({ element, type, data, options, clb });
  }
  return pubSub;
}

const logElement = (
  element: HTMLElement,
  type: any,
  data: any,
  options: LogElementOptions,
  clb?: (metaElement) => void
) => {
  //If Element is not connected to the DOM we step out of the function;
  if (!element.isConnected) return false;

  //If there are elements we want to wait before logging the element
  //We observe all logged metaElements
  //And check if all `waitFor` elements are logged
  if (options?.waitFor?.length) {
    const hasAllParents = options.waitFor?.every((elm) =>
      lookup.get(getElement(elm))
    );
    if (hasAllParents) {
      //All parents are allready logged
      //We can log the element
      addElementAndNotify(element, type, data, (element) => {
        clb && clb(element);
        return true;
      });
    } else {
      //Status check for logging.
      //'init' is initial position,
      //'logging' means that it's in process of logging
      //'finished' used to exit the loop
      let loggingStatus: "init" | "logging" | "finished" = "init";

      // waitForState holds the islogged state for each waitfor element
      let waitForState = options.waitFor?.map((elm, index) => {
        return { index: index, islogged: false };
      });
      const eventHandler = (events) => {
        if (loggingStatus !== "finished") {
          //@ts-ignore
          events?.detail?.forEach((metaNode) => {
            const element = metaNode;
            if (element) {
              //Looping through every `waitFor` element
              //And checking if it's logged.
              //If it all `waitFor` elements are logged/
              //We can proceede with logging the element
              options.waitFor.forEach((el, index) => {
                if (el instanceof Element && el.isSameNode(element?.element)) {
                  waitForState[index].islogged = true;
                } else if (
                  el instanceof MetaNode &&
                  (el.element as Element).isSameNode(element?.element)
                ) {
                  waitForState[index].islogged = true;
                } else if (
                  typeof el === "string" &&
                  (element?.element as Element).id === el
                ) {
                  waitForState[index].islogged = true;
                }
              });
              const hasEvery = waitForState.every(
                (state) => state.islogged === true
              );
              if (hasEvery) loggingStatus = "logging";
            }
          });
          if (loggingStatus === "logging") {
            //Logging is done now and the status can be set to finished
            //We stop the observer as well
            // observer.disconnect();
            window.removeEventListener("adplogger:store-update", eventHandler);
            loggingStatus = "finished";
            addElementAndNotify(element, type, data, (element) => {
              clb && clb(element);
              return true;
            });
            /* pubSub.publish('metaNodeAdded', addElementAndNotify(element, type, data)) */
            return false;
          }
        }
      };

      //Listen to adding meta elements
      window.addEventListener("adplogger:store-update", eventHandler);
    }
  } else {
    //No `waitFor` elements means that we just
    //Want to log the element without waiting for any other meta elements
    /*  observer.disconnect(); */
    addElementAndNotify(element, type, data, clb);
    /*  pubSub.publish('metaNodeAdded', addElementAndNotify(element, type, data)) */
  }
};

//Adding the element to the metaNode tree
//Notifying the inscreen observer that an element has been added
//And returning the node
const addElementAndNotify = (element, type, data, added: (element) => void) => {
  window.dispatchEvent(
    new CustomEvent("adplogger-sdk:meta-element-added", {
      detail: [element, type, data, false],
    })
  );
  window.addEventListener(
    "adplogger-core:meta-element-added",
    (evt) => {
      added && added!(evt.detail);
    },
    {
      once: true,
    }
  );
};
