import { RequireADPType } from "@amedia/adplogger-logger/declarations/logger";
import { Events } from "@amedia/adplogger-logger/declarations/store/state";
import { SDKStore, SDKState } from "src/store";
type Payloads = RequireADPType<"payload", Events>;
import store from "@amedia/adplogger-logger/src/shared/state";

/**
 * Subscribes to ADPEvents and sends data {@link declarations.RequireADPType | Payload | null} and array of data {@link declarations.RequireADPType | Payload[] }
 *
 * When callback fires first time, data is always null.
 *
 * arr is sending all event data accumulated.
 * @example
 * Create a basic subscription to clickEvents:
 *
 * ```ts
 * import {subscribeToADPEvent} from '@amedia/adplogger-sdk'
 *
 * subscribeToADPEvent('InscreenEvent', event => {
 *     console.log(event)
 * })
 * ```
 *
 * {@link https://secure.amedia.cloud/api/amedia-sandbox/v1/adplogger/sdk/observe-event/JxHRToIKeM4wWBpmZ7kq | Playground}
 *
 * @example
 * Unsubscribe from events
 *
 * ```ts
 * import {subscribeToADPEvent} from '@amedia/adplogger2'
 *
 * const subscriber = await subscribeToADPEvent('InscreenEvent', event => {
 *  console.log(event)
 * })
 *
 * subscriber.unsubscribe()
 * ```
 *
 *
 * @param event Takes an event of typeof {@link declarations.Events}
 * @param callback Takes a callback fuction of 2 named params, data and arr.
 */
export function subscribeToADPEvent<
  Event extends Events,
  Payload extends Payloads
>(
  event: Event,
  callback: ({
    data,
    arr,
  }: {
    data: Payload | null;
    arr: Array<Payload>;
  }) => void
) {
  const sdkStore = SDKState.store;
  let subscriber: {
    unsubscribe: () => void;
  };

  if (sdkStore) {
    const obj = {
      data: null,
      arr: sdkStore.state[`${event}s`],
    } as unknown as { data: Payload; arr: Array<Payload> };
    callback(obj);
    subscriber = sdkStore.events.subscribe(event, (data) => {
      const obj = {
        data,
        arr: sdkStore.state[`${event}s`],
      } as unknown as { data: Payload; arr: Array<Payload> };
      callback(obj);
    });
    return subscriber;
  } else {
    store.then((store) => {
      store.events.subscribe("Page", (page) => {
        if (page) {
          const obj = {
            data: null,
            arr: store.state[`${event}s`],
          } as unknown as { data: Payload; arr: Array<Payload> };
          callback(obj);
          subscriber = store.events.subscribe(event, (data) => {
            const obj = {
              data,
              arr: store.state[`${event}s`],
            } as unknown as { data: Payload; arr: Array<Payload> };
            callback(obj);
          });
        }
      });
    });
  }
  return subscriber;
}
