import store from "@amedia/adplogger-logger/src/shared/state";
import { addMetaEvent, subscribeToADPEvent } from "./event";
import { addMetaElement, subscribeToADPMeta } from "./meta";
import { SDKState } from "./store";

const DEFAULT_WAIT = 50;

const sendUnset = async () => {
  if (SDKState.unsentMetaElements.length) {
    await sendAllUnsent(SDKState.unsentMetaElements);
  }
  if (SDKState.unsentMetaEvents.length) {
    await sendAllUnsent(SDKState.unsentMetaEvents);
  }
};

// Sending all unset meta and events
const sendAllUnsent = (elements: Array<any>) => {
  return new Promise((res) => {
    elements.forEach((send, index) =>
      setTimeout(() => {
        send();
        if (index === elements.length) {
          res(true);
        }
      }, index * DEFAULT_WAIT)
    );
  });
};

/**
 *
 * @param {Object} options Options
 * @param {boolean} options.dryRun Use dryRun to test the SDK without sending data to the backend
 *
 * Sets SDK options.
 */
const setSDKOptions = ({ dryRun = false }: { dryRun: boolean }) => {
  SDKState.dryRun = dryRun;
};

(async () => {
  //Adplogger2 is instantiated in the logger package
  if ("Adplogger2" in window && globalThis?.Adplogger2?.ready) {
    SDKState.isReady = true;
    window.dispatchEvent(
      new CustomEvent("adplogger-sdk:dry-run", {
        detail: SDKState.dryRun,
      })
    );
    await sendUnset();
  } else {
    //We subscribe to when the logger package has logged a Page
    window.addEventListener(
      "adplogger:store-init",
      async (store: CustomEvent) => {
        if (store) {
          SDKState.isReady = true;
          window.dispatchEvent(
            new CustomEvent("adplogger-sdk:dry-run", {
              detail: SDKState.dryRun,
            })
          );
          await sendUnset();
        }
      }
    );
  }
})();

export {
  subscribeToADPEvent,
  subscribeToADPMeta,
  addMetaElement,
  addMetaEvent,
  setSDKOptions,
};
