import Store from "@amedia/adplogger-logger/src/shared/state/store/store";

export class SDKStore {
  private static instance: SDKStore;
  store: Store;
  unsentMetaElements: Array<any> = [];
  unsentMetaEvents: Array<any> = [];
  isReady: boolean = false;
  dryRun: boolean = false;
  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    /* this.getStore().then(store => this.store = store) */
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): SDKStore {
    if (!SDKStore.instance) {
      SDKStore.instance = new SDKStore();
    }

    return SDKStore.instance;
  }
}

export const SDKState = SDKStore.getInstance();
