import { ADPEvent, Id } from "@amedia/adplogger-logger/declarations";
import {
  PickFromADP,
  RequireADPType,
} from "@amedia/adplogger-logger/declarations/logger";
import {
  events,
  Events,
} from "@amedia/adplogger-logger/declarations/store/state";
import { MetaNode } from "@amedia/adplogger-logger/src/shared/logger";
import { getElement } from "@amedia/adplogger-logger/src/shared/utils";
import { SDKState } from "src/store";

const allowedEvents = [
  "CustomEvent",
  "VideoEvent",
  "PlaybackAdEvent",
  "ErrorEvent",
  "ClickEvent",
  "AdslotEvent",
] as const;
type AllowedEvents = (typeof allowedEvents)[number];

/**
 *
 * @param element Takes an element {@link exported.MetaNode | MetaNode} type. This is the element you want to log.
 * @param type Takes a key of {@link declarations.Events}
 * @param payload Takes payload of typeof {@link declarations.ADPEvent}
 * @param callback Callback that emits if event is logged, and data that is either en error message, or provided type and payload
 *
 * @example
 *
 * Add Meta Event after logging MetaElement
 *
 * ```ts
 * import { addMetaElement, addMetaEvent, getSelector } from '@amedia/adplogger-sdk'
 * const parentElement = document.createElement('div')
 * parentElement.id = 'parent'
 * parentElement.innerHTML = 'I am a parent element'
 *
 * addMetaElement(parentElement, 'Article', {
 *     title: 'I am a parent'
 * }, null, metaElement => {
 *     metaElement.element?.addEventListener('click', (event) => {
 *         addMetaEvent(metaElement, 'ClickEvent', {
 *             type: 'click',
 *             cssSelector: getSelector(parentElement),
 *             coordinateX: Math.round(event.pageX),
 *             coordinateY: Math.round(event.pageY),
 *             clickLabel: 'Some label',
 *             clickValue: 'Some value'
 *         }, ev => {
 *             console.log('Click Event', ev)
 *         })
 *     })
 *     console.log('Parent element', metaElement)
 * })
 *
 * document.body.append(parentElement)
 *
 * ```
 *
 * {@link https://secure.amedia.cloud/api/amedia-sandbox/v1/adplogger/sdk/add-meta-event/tsr5mA4C1SAREoFv8i2D | Playground}
 */

export function addMetaEvent<
  Event extends PickFromADP<ADPEvent, AllowedEvents>,
  Payload extends Partial<RequireADPType<"payload", Event>>
>(
  element: MetaNode | Element | Id,
  type: Event,
  payload: Payload,
  callback?: ({ isLogged, data }) => void
) {
  if (!isAllowed(type)) {
    console.error(
      `${JSON.stringify(
        type
      )} is not allowed. Allowed types are: ${JSON.stringify(allowedEvents)}`
    );
    return;
  }
  if (!isEvent(type)) {
    console.error(`${JSON.stringify(type)} if not of type Event`);
    return;
  }

  const eventElement = getElement(element);
  sendEvent(type, payload, eventElement, callback);
}

const isEvent = (event: string): event is Events => {
  return events.indexOf(event as Events) > -1;
};
const isAllowed = (type: AllowedEvents): type is AllowedEvents => {
  return allowedEvents.includes(type);
};

const sendEvent = (type, payload, eventElement, callback) => {
  if (SDKState.isReady) {
    sendEventToADPBridge(type, payload, eventElement, callback);
  } else {
    SDKState.unsentMetaEvents.push(
      sendEventToADPBridge.bind(this, type, payload, eventElement, callback)
    );
  }
};

const sendEventToADPBridge = (type, payload, eventElement, callback?) => {
  window.dispatchEvent(
    new CustomEvent("adplogger-sdk:meta-event-added", {
      detail: { type, payload, eventElement },
    })
  );
  window.addEventListener(
    "adplogger-core:meta-event-added",
    (evt) => {
      callback && callback!(evt.detail);
    },
    {
      once: true,
    }
  );
};
